/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CategorieArticleDto } from '../models/categorie-article-dto';
import { CategorieArticleRequestDto } from '../models/categorie-article-request-dto';

@Injectable({
  providedIn: 'root',
})
export class CategorieArticleControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllCategorieArticles
   */
  static readonly GetAllCategorieArticlesPath = '/api/v2/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCategorieArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCategorieArticles$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CategorieArticleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CategorieArticleControllerService.GetAllCategorieArticlesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CategorieArticleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCategorieArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCategorieArticles(params?: {
  }): Observable<Array<CategorieArticleDto>> {

    return this.getAllCategorieArticles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CategorieArticleDto>>) => r.body as Array<CategorieArticleDto>)
    );
  }

  /**
   * Path part for operation saveCategorieArticle
   */
  static readonly SaveCategorieArticlePath = '/api/v2/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCategorieArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCategorieArticle$Response(params: {
    body: CategorieArticleRequestDto
  }): Observable<StrictHttpResponse<CategorieArticleDto>> {

    const rb = new RequestBuilder(this.rootUrl, CategorieArticleControllerService.SaveCategorieArticlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategorieArticleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCategorieArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCategorieArticle(params: {
    body: CategorieArticleRequestDto
  }): Observable<CategorieArticleDto> {

    return this.saveCategorieArticle$Response(params).pipe(
      map((r: StrictHttpResponse<CategorieArticleDto>) => r.body as CategorieArticleDto)
    );
  }

  /**
   * Path part for operation getOneCategorieArticle
   */
  static readonly GetOneCategorieArticlePath = '/api/v2/categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneCategorieArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneCategorieArticle$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<CategorieArticleDto>> {

    const rb = new RequestBuilder(this.rootUrl, CategorieArticleControllerService.GetOneCategorieArticlePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategorieArticleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneCategorieArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneCategorieArticle(params: {
    id: number;
  }): Observable<CategorieArticleDto> {

    return this.getOneCategorieArticle$Response(params).pipe(
      map((r: StrictHttpResponse<CategorieArticleDto>) => r.body as CategorieArticleDto)
    );
  }

  /**
   * Path part for operation updateCategorieArticle
   */
  static readonly UpdateCategorieArticlePath = '/api/v2/categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCategorieArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategorieArticle$Response(params: {
    id: number;
    body: CategorieArticleRequestDto
  }): Observable<StrictHttpResponse<CategorieArticleDto>> {

    const rb = new RequestBuilder(this.rootUrl, CategorieArticleControllerService.UpdateCategorieArticlePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CategorieArticleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCategorieArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCategorieArticle(params: {
    id: number;
    body: CategorieArticleRequestDto
  }): Observable<CategorieArticleDto> {

    return this.updateCategorieArticle$Response(params).pipe(
      map((r: StrictHttpResponse<CategorieArticleDto>) => r.body as CategorieArticleDto)
    );
  }

  /**
   * Path part for operation deleteCategorieArticle
   */
  static readonly DeleteCategorieArticlePath = '/api/v2/categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCategorieArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCategorieArticle$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CategorieArticleControllerService.DeleteCategorieArticlePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCategorieArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCategorieArticle(params: {
    id: number;
  }): Observable<boolean> {

    return this.deleteCategorieArticle$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
