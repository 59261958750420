export interface LoginContainerState {
  username: string;
  password: string;
  rememberMe: boolean;
  hasError: boolean;
  errorMessage: string;
  isLoading: boolean;
  loadingMessage;
}

export const initialLoginContainerState: LoginContainerState = {
  username: '',
  password: '',
  rememberMe: false,
  hasError: false,
  errorMessage: null,
  isLoading: false,
  loadingMessage: null
};
