import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from "./shared/shared.module";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

import {PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {AppComponent} from './app.component';
import {ContentLayoutComponent} from "./layouts/content/content-layout.component";
import {FullLayoutComponent} from "./layouts/full/full-layout.component";

import {FormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {ErrorComponent} from './error/error.component';
import {ToasterServices} from "./shared/services/toastr.service";
import {LogoutComponent} from './logout/logout.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {ApiModule} from '../api/api.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AppStore} from "./store/app.store";
import {LocalAuthService} from "./shared/local-auth-service";
import {ApiInterceptor} from "./shared/api-interceptor";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        LoginComponent,
        ErrorComponent,
        LogoutComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        NgbModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        PerfectScrollbarModule,
        ApiModule,
        NgxPermissionsModule.forRoot()
        
    ],
    providers: [
        AppStore,
        LocalAuthService,
        {provide : LocationStrategy , useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS,useClass: ApiInterceptor,multi:true},
        ToasterServices,
        
        
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
