/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Response } from '../models/response';
import { SiteDto } from '../models/site-dto';

@Injectable({
  providedIn: 'root',
})
export class SiteControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllSites
   */
  static readonly GetAllSitesPath = '/api/v2/sites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSites()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSites$Response(params?: {
  }): Observable<StrictHttpResponse<Array<SiteDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteControllerService.GetAllSitesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SiteDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSites(params?: {
  }): Observable<Array<SiteDto>> {

    return this.getAllSites$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SiteDto>>) => r.body as Array<SiteDto>)
    );
  }

  /**
   * Path part for operation addSite
   */
  static readonly AddSitePath = '/api/v2/sites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSite$Response(params: {
    body: SiteDto
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, SiteControllerService.AddSitePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSite(params: {
    body: SiteDto
  }): Observable<Response> {

    return this.addSite$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

  /**
   * Path part for operation getOneSite
   */
  static readonly GetOneSitePath = '/api/v2/sites/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneSite()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneSite$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, SiteControllerService.GetOneSitePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneSite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneSite(params: {
    id: number;
  }): Observable<Response> {

    return this.getOneSite$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

  /**
   * Path part for operation updateSite
   */
  static readonly UpdateSitePath = '/api/v2/sites/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSite$Response(params: {
    id: number;
    body: SiteDto
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, SiteControllerService.UpdateSitePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSite(params: {
    id: number;
    body: SiteDto
  }): Observable<Response> {

    return this.updateSite$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

  /**
   * Path part for operation deleteSite
   */
  static readonly DeleteSitePath = '/api/v2/sites/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSite()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSite$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, SiteControllerService.DeleteSitePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSite(params: {
    id: number;
  }): Observable<Response> {

    return this.deleteSite$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

}
