/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OperationResponseDto } from '../models/operation-response-dto';

@Injectable({
  providedIn: 'root',
})
export class OperationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation retirer
   */
  static readonly RetirerPath = '/api/v2/operations/retrait';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retirer()` instead.
   *
   * This method doesn't expect any request body.
   */
  retirer$Response(params: {
    compte: number;
    montant: number;
  }): Observable<StrictHttpResponse<OperationResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationControllerService.RetirerPath, 'put');
    if (params) {
      rb.query('compte', params.compte, {});
      rb.query('montant', params.montant, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OperationResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retirer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retirer(params: {
    compte: number;
    montant: number;
  }): Observable<OperationResponseDto> {

    return this.retirer$Response(params).pipe(
      map((r: StrictHttpResponse<OperationResponseDto>) => r.body as OperationResponseDto)
    );
  }

  /**
   * Path part for operation retirerMarchandise
   */
  static readonly RetirerMarchandisePath = '/api/v2/operations/retrait/commande';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retirerMarchandise()` instead.
   *
   * This method doesn't expect any request body.
   */
  retirerMarchandise$Response(params: {
    compte: number;
    montant: number;
    commandeId: number;
  }): Observable<StrictHttpResponse<OperationResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationControllerService.RetirerMarchandisePath, 'put');
    if (params) {
      rb.query('compte', params.compte, {});
      rb.query('montant', params.montant, {});
      rb.query('commandeId', params.commandeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OperationResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retirerMarchandise$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retirerMarchandise(params: {
    compte: number;
    montant: number;
    commandeId: number;
  }): Observable<OperationResponseDto> {

    return this.retirerMarchandise$Response(params).pipe(
      map((r: StrictHttpResponse<OperationResponseDto>) => r.body as OperationResponseDto)
    );
  }

  /**
   * Path part for operation verser
   */
  static readonly VerserPath = '/api/v2/operations/versement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verser()` instead.
   *
   * This method doesn't expect any request body.
   */
  verser$Response(params: {
    compte: number;
    montant: number;
  }): Observable<StrictHttpResponse<OperationResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, OperationControllerService.VerserPath, 'put');
    if (params) {
      rb.query('compte', params.compte, {});
      rb.query('montant', params.montant, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OperationResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verser(params: {
    compte: number;
    montant: number;
  }): Observable<OperationResponseDto> {

    return this.verser$Response(params).pipe(
      map((r: StrictHttpResponse<OperationResponseDto>) => r.body as OperationResponseDto)
    );
  }

  /**
   * Path part for operation getOperation
   */
  static readonly GetOperationPath = '/api/v2/operations/{compte}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOperation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperation$Response(params: {
    compte: number;
  }): Observable<StrictHttpResponse<Array<OperationResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OperationControllerService.GetOperationPath, 'get');
    if (params) {
      rb.path('compte', params.compte, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OperationResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOperation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperation(params: {
    compte: number;
  }): Observable<Array<OperationResponseDto>> {

    return this.getOperation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OperationResponseDto>>) => r.body as Array<OperationResponseDto>)
    );
  }

}
