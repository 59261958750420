/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ArticleControllerService } from './services/article-controller.service';
import { BonCommandeControllerService } from './services/bon-commande-controller.service';
import { BoutiqueControllerService } from './services/boutique-controller.service';
import { CategorieArticleControllerService } from './services/categorie-article-controller.service';
import { ChargeControllerService } from './services/charge-controller.service';
import { ClientControllerService } from './services/client-controller.service';
import { CommandeControllerService } from './services/commande-controller.service';
import { CompteControllerService } from './services/compte-controller.service';
import { DepenseControllerService } from './services/depense-controller.service';
import { EmplacementControllerService } from './services/emplacement-controller.service';
import { ExtensionControllerService } from './services/extension-controller.service';
import { FactureControllerService } from './services/facture-controller.service';
import { FournisseurControllerService } from './services/fournisseur-controller.service';
import { LigneCommandeControllerService } from './services/ligne-commande-controller.service';
import { MagasinArticlesControllerService } from './services/magasin-articles-controller.service';
import { MagasinControllerService } from './services/magasin-controller.service';
import { MarqueControllerService } from './services/marque-controller.service';
import { OperationControllerService } from './services/operation-controller.service';
import { ReceptionControllerService } from './services/reception-controller.service';
import { RemiseControllerService } from './services/remise-controller.service';
import { SiteControllerService } from './services/site-controller.service';
import { StockDisponibleControllerService } from './services/stock-disponible-controller.service';
import { StockentreControllerService } from './services/stockentre-controller.service';
import { StockSortieControllerService } from './services/stock-sortie-controller.service';
import { AccountControllerService } from './services/account-controller.service';
import { AuthenticationControllerService } from './services/authentication-controller.service';
import { MediaControllerService } from './services/media-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ArticleControllerService,
    BonCommandeControllerService,
    BoutiqueControllerService,
    CategorieArticleControllerService,
    ChargeControllerService,
    ClientControllerService,
    CommandeControllerService,
    CompteControllerService,
    DepenseControllerService,
    EmplacementControllerService,
    ExtensionControllerService,
    FactureControllerService,
    FournisseurControllerService,
    LigneCommandeControllerService,
    MagasinArticlesControllerService,
    MagasinControllerService,
    MarqueControllerService,
    OperationControllerService,
    ReceptionControllerService,
    RemiseControllerService,
    SiteControllerService,
    StockDisponibleControllerService,
    StockentreControllerService,
    StockSortieControllerService,
    AccountControllerService,
    AuthenticationControllerService,
    MediaControllerService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
