/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LigneCommande } from '../models/ligne-commande';
import { LigneCommandeDto } from '../models/ligne-commande-dto';

@Injectable({
  providedIn: 'root',
})
export class LigneCommandeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addBonArticle
   */
  static readonly AddBonArticlePath = '/api/v2/ligne-commande';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBonArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBonArticle$Response(params: {
    body: LigneCommandeDto
  }): Observable<StrictHttpResponse<LigneCommande>> {

    const rb = new RequestBuilder(this.rootUrl, LigneCommandeControllerService.AddBonArticlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LigneCommande>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addBonArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBonArticle(params: {
    body: LigneCommandeDto
  }): Observable<LigneCommande> {

    return this.addBonArticle$Response(params).pipe(
      map((r: StrictHttpResponse<LigneCommande>) => r.body as LigneCommande)
    );
  }

  /**
   * Path part for operation updateBonArticlesBatch
   */
  static readonly UpdateBonArticlesBatchPath = '/api/v2/ligne-commande/batch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBonArticlesBatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBonArticlesBatch$Response(params: {
    body: Array<LigneCommandeDto>
  }): Observable<StrictHttpResponse<Array<LigneCommande>>> {

    const rb = new RequestBuilder(this.rootUrl, LigneCommandeControllerService.UpdateBonArticlesBatchPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LigneCommande>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateBonArticlesBatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBonArticlesBatch(params: {
    body: Array<LigneCommandeDto>
  }): Observable<Array<LigneCommande>> {

    return this.updateBonArticlesBatch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LigneCommande>>) => r.body as Array<LigneCommande>)
    );
  }

  /**
   * Path part for operation addBonArticles
   */
  static readonly AddBonArticlesPath = '/api/v2/ligne-commande/batch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBonArticles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBonArticles$Response(params: {
    body: Array<LigneCommandeDto>
  }): Observable<StrictHttpResponse<Array<LigneCommande>>> {

    const rb = new RequestBuilder(this.rootUrl, LigneCommandeControllerService.AddBonArticlesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LigneCommande>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addBonArticles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBonArticles(params: {
    body: Array<LigneCommandeDto>
  }): Observable<Array<LigneCommande>> {

    return this.addBonArticles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LigneCommande>>) => r.body as Array<LigneCommande>)
    );
  }

  /**
   * Path part for operation getLigneCommandeByIdBon
   */
  static readonly GetLigneCommandeByIdBonPath = '/api/v2/ligne-commande/{idBon}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLigneCommandeByIdBon()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLigneCommandeByIdBon$Response(params: {
    idBon: number;
  }): Observable<StrictHttpResponse<Array<LigneCommande>>> {

    const rb = new RequestBuilder(this.rootUrl, LigneCommandeControllerService.GetLigneCommandeByIdBonPath, 'get');
    if (params) {
      rb.path('idBon', params.idBon, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LigneCommande>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLigneCommandeByIdBon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLigneCommandeByIdBon(params: {
    idBon: number;
  }): Observable<Array<LigneCommande>> {

    return this.getLigneCommandeByIdBon$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LigneCommande>>) => r.body as Array<LigneCommande>)
    );
  }

  /**
   * Path part for operation deleteLigneCmdeBatch
   */
  static readonly DeleteLigneCmdeBatchPath = '/api/v2/ligne-commande/{ids}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLigneCmdeBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLigneCmdeBatch$Response(params: {
    ids: Array<number>;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LigneCommandeControllerService.DeleteLigneCmdeBatchPath, 'delete');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteLigneCmdeBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLigneCmdeBatch(params: {
    ids: Array<number>;
  }): Observable<void> {

    return this.deleteLigneCmdeBatch$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateBonArticle
   */
  static readonly UpdateBonArticlePath = '/api/v2/ligne-commande/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBonArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBonArticle$Response(params: {
    id: number;
    body: LigneCommandeDto
  }): Observable<StrictHttpResponse<LigneCommande>> {

    const rb = new RequestBuilder(this.rootUrl, LigneCommandeControllerService.UpdateBonArticlePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LigneCommande>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateBonArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBonArticle(params: {
    id: number;
    body: LigneCommandeDto
  }): Observable<LigneCommande> {

    return this.updateBonArticle$Response(params).pipe(
      map((r: StrictHttpResponse<LigneCommande>) => r.body as LigneCommande)
    );
  }

}
