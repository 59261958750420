import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {BoutiqueControllerService} from "../api/services/boutique-controller.service";
import {LocalAuthService} from "./shared/local-auth-service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router,private boutiqueService: BoutiqueControllerService,private localAuth: LocalAuthService) {
        
    }

    ngOnInit() {

        this.localAuth.loadPermission(this.localAuth.getUserDetails());

        this.loadBoutique();

        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }

    loadBoutique(){
        this.boutiqueService.getAllBoutiques().subscribe(data=>{
            this.localAuth.setStore(data[0])
        })
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}
