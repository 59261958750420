import {Injectable} from '@angular/core';
import {NgxPermissionsService} from "ngx-permissions";
import {BoutiqueDto} from "../../api/models/boutique-dto";
import {Users} from "../../api/models/users";

@Injectable()
export class LocalAuthService {

    AUTH_TOKEN_IDENTIFIER = 'auth-token';
    USER_DETAILS_IDENTIFIER = 'user-details';
    authToken = null;
    userDetails = null;
    boutique : BoutiqueDto = {}

    constructor(
        private permission: NgxPermissionsService
    ){}

    isAuthenticated() {
        if (this.getAuthToken() != null && this.getUserDetails() != null) {
            return true;
        } else {
            return false;
        }
    }

    logout() {
        localStorage.removeItem(this.AUTH_TOKEN_IDENTIFIER);
        localStorage.removeItem(this.USER_DETAILS_IDENTIFIER);
    }

    getAuthToken(): string {
        this.authToken = localStorage.getItem(this.AUTH_TOKEN_IDENTIFIER);
        return this.authToken;
    }

    setToken(authToken: string, remember: boolean) {
        this.authToken = authToken;
        localStorage.setItem(this.AUTH_TOKEN_IDENTIFIER, authToken);
    }

    setStore(boutiqueDto: BoutiqueDto){
        localStorage.setItem("boutique",JSON.stringify(boutiqueDto));
    }

    getStore(): BoutiqueDto{
        this.boutique = JSON.parse(localStorage.getItem("boutique"));
        return this.boutique;
    }

    getUserDetails(): Users {
        if (this.userDetails) {
            return this.userDetails;
        }
        this.userDetails = JSON.parse(localStorage.getItem(this.USER_DETAILS_IDENTIFIER));
        return this.userDetails;
    }

    setUserDetails(userDetails: Users, remember: boolean) {
        this.userDetails = userDetails;
        localStorage.setItem(this.USER_DETAILS_IDENTIFIER, JSON.stringify(userDetails));
    }

    loadPermission(user:Users){
        if (user!= null) {
            this.permission.loadPermissions(user.roles.map(value => value.name));
        }
    }
}
