/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Remise } from '../models/remise';
import { RemiseDto } from '../models/remise-dto';

@Injectable({
  providedIn: 'root',
})
export class RemiseControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllRemises
   */
  static readonly GetAllRemisesPath = '/api/v2/remises';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRemises()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRemises$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Remise>>> {

    const rb = new RequestBuilder(this.rootUrl, RemiseControllerService.GetAllRemisesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Remise>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllRemises$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRemises(params?: {
  }): Observable<Array<Remise>> {

    return this.getAllRemises$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Remise>>) => r.body as Array<Remise>)
    );
  }

  /**
   * Path part for operation addRemise
   */
  static readonly AddRemisePath = '/api/v2/remises';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRemise()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRemise$Response(params: {
    body: RemiseDto
  }): Observable<StrictHttpResponse<Remise>> {

    const rb = new RequestBuilder(this.rootUrl, RemiseControllerService.AddRemisePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Remise>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRemise$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRemise(params: {
    body: RemiseDto
  }): Observable<Remise> {

    return this.addRemise$Response(params).pipe(
      map((r: StrictHttpResponse<Remise>) => r.body as Remise)
    );
  }

  /**
   * Path part for operation getAllRemiseIsActived
   */
  static readonly GetAllRemiseIsActivedPath = '/api/v2/remises/actived';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRemiseIsActived()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRemiseIsActived$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Remise>>> {

    const rb = new RequestBuilder(this.rootUrl, RemiseControllerService.GetAllRemiseIsActivedPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Remise>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllRemiseIsActived$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRemiseIsActived(params?: {
  }): Observable<Array<Remise>> {

    return this.getAllRemiseIsActived$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Remise>>) => r.body as Array<Remise>)
    );
  }

  /**
   * Path part for operation getOneRemise
   */
  static readonly GetOneRemisePath = '/api/v2/remises/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneRemise()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneRemise$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Remise>> {

    const rb = new RequestBuilder(this.rootUrl, RemiseControllerService.GetOneRemisePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Remise>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneRemise$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneRemise(params: {
    id: number;
  }): Observable<Remise> {

    return this.getOneRemise$Response(params).pipe(
      map((r: StrictHttpResponse<Remise>) => r.body as Remise)
    );
  }

  /**
   * Path part for operation updateRemise
   */
  static readonly UpdateRemisePath = '/api/v2/remises/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRemise()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRemise$Response(params: {
    id: number;
    body: RemiseDto
  }): Observable<StrictHttpResponse<Remise>> {

    const rb = new RequestBuilder(this.rootUrl, RemiseControllerService.UpdateRemisePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Remise>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRemise$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRemise(params: {
    id: number;
    body: RemiseDto
  }): Observable<Remise> {

    return this.updateRemise$Response(params).pipe(
      map((r: StrictHttpResponse<Remise>) => r.body as Remise)
    );
  }

  /**
   * Path part for operation setActive1
   */
  static readonly SetActive1Path = '/api/v2/remises/{id}/active/{active}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setActive1()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActive1$Response(params: {
    id: number;
    active: boolean;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RemiseControllerService.SetActive1Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setActive1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActive1(params: {
    id: number;
    active: boolean;
  }): Observable<void> {

    return this.setActive1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
