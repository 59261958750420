import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {LocalAuthService} from "../local-auth-service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: LocalAuthService,private route: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //return this.authService.isAuthenticated();

    if (this.authService.isAuthenticated()){
      return true;

    }
    this.route.navigate(['login']);
    return false;
  }
}
