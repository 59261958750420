/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmplacementDto } from '../models/emplacement-dto';
import { EmplacementRequestDto } from '../models/emplacement-request-dto';

@Injectable({
  providedIn: 'root',
})
export class EmplacementControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllEmplacements
   */
  static readonly GetAllEmplacementsPath = '/api/v2/emplacements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllEmplacements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEmplacements$Response(params?: {
  }): Observable<StrictHttpResponse<Array<EmplacementDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EmplacementControllerService.GetAllEmplacementsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmplacementDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllEmplacements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEmplacements(params?: {
  }): Observable<Array<EmplacementDto>> {

    return this.getAllEmplacements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmplacementDto>>) => r.body as Array<EmplacementDto>)
    );
  }

  /**
   * Path part for operation saveEmplacement
   */
  static readonly SaveEmplacementPath = '/api/v2/emplacements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveEmplacement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveEmplacement$Response(params: {
    body: EmplacementRequestDto
  }): Observable<StrictHttpResponse<EmplacementDto>> {

    const rb = new RequestBuilder(this.rootUrl, EmplacementControllerService.SaveEmplacementPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmplacementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveEmplacement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveEmplacement(params: {
    body: EmplacementRequestDto
  }): Observable<EmplacementDto> {

    return this.saveEmplacement$Response(params).pipe(
      map((r: StrictHttpResponse<EmplacementDto>) => r.body as EmplacementDto)
    );
  }

  /**
   * Path part for operation getOneEmplacement
   */
  static readonly GetOneEmplacementPath = '/api/v2/emplacements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneEmplacement()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneEmplacement$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<EmplacementDto>> {

    const rb = new RequestBuilder(this.rootUrl, EmplacementControllerService.GetOneEmplacementPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmplacementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneEmplacement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneEmplacement(params: {
    id: number;
  }): Observable<EmplacementDto> {

    return this.getOneEmplacement$Response(params).pipe(
      map((r: StrictHttpResponse<EmplacementDto>) => r.body as EmplacementDto)
    );
  }

  /**
   * Path part for operation updateEmplacement
   */
  static readonly UpdateEmplacementPath = '/api/v2/emplacements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEmplacement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmplacement$Response(params: {
    id: number;
    body: EmplacementRequestDto
  }): Observable<StrictHttpResponse<EmplacementDto>> {

    const rb = new RequestBuilder(this.rootUrl, EmplacementControllerService.UpdateEmplacementPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmplacementDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEmplacement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmplacement(params: {
    id: number;
    body: EmplacementRequestDto
  }): Observable<EmplacementDto> {

    return this.updateEmplacement$Response(params).pipe(
      map((r: StrictHttpResponse<EmplacementDto>) => r.body as EmplacementDto)
    );
  }

  /**
   * Path part for operation deleteEmplacement
   */
  static readonly DeleteEmplacementPath = '/api/v2/emplacements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEmplacement()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmplacement$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, EmplacementControllerService.DeleteEmplacementPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEmplacement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEmplacement(params: {
    id: number;
  }): Observable<boolean> {

    return this.deleteEmplacement$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
