import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {FullLayoutComponent} from "./layouts/full/full-layout.component";
import {ContentLayoutComponent} from "./layouts/content/content-layout.component";

import {Full_ROUTES} from "./shared/routes/full-layout.routes";
import {CONTENT_ROUTES} from "./shared/routes/content-layout.routes";

import {AuthGuard} from './shared/auth/auth-guard.service';
import {LoginComponent} from './login/login.component';
import {ErrorComponent} from './error/error.component';
import {LogoutComponent} from "./logout/logout.component";

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    { path: 'login', component: LoginComponent, data: { title: 'login' }},
    { path: 'logout', component: LogoutComponent,canActivate:[AuthGuard]},
    { path: '',
        component: FullLayoutComponent,
        canActivate: [AuthGuard],
        children: Full_ROUTES
    },
    { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },

    {
        path: '**',
        component: ErrorComponent
    }

];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
