import {Injectable} from '@angular/core';


@Injectable()
export class AppStore {
  private states: any = {};

  has(name: string) {
    return this.states.hasOwnProperty(name);
  }
  get(name: string, initialValue?: object) {
    if (this.states.hasOwnProperty(name)) {
      return this.states[name];
    }
    this.store(name, initialValue);
    return this.states[name];
  }
  store(name: string, value: object) {
    this.states[name] = JSON.parse(JSON.stringify(value));
  }
}
