import {Injectable} from '@angular/core';

declare var toastr:any;

@Injectable({
    providedIn: 'root'
})
export class ToasterServices {

    constructor() { }

    Success(title:string,message?:string){
        toastr.success(message,title)
    }

    Warning(title:string,message?:string){
        toastr.warning(message,title)
    }

    Error(title:string,message?:string){
        toastr.error(message,title)
    }

    Info(message:string){
        toastr.info(message)
    }
}