/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MagasinDto } from '../models/magasin-dto';
import { Response } from '../models/response';

@Injectable({
  providedIn: 'root',
})
export class MagasinControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllMagasins
   */
  static readonly GetAllMagasinsPath = '/api/v2/magasins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMagasins()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMagasins$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MagasinDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinControllerService.GetAllMagasinsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MagasinDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllMagasins$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMagasins(params?: {
  }): Observable<Array<MagasinDto>> {

    return this.getAllMagasins$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MagasinDto>>) => r.body as Array<MagasinDto>)
    );
  }

  /**
   * Path part for operation addMagasin
   */
  static readonly AddMagasinPath = '/api/v2/magasins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMagasin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMagasin$Response(params: {
    body: MagasinDto
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinControllerService.AddMagasinPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addMagasin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMagasin(params: {
    body: MagasinDto
  }): Observable<Response> {

    return this.addMagasin$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

  /**
   * Path part for operation getOneMagasin
   */
  static readonly GetOneMagasinPath = '/api/v2/magasins/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneMagasin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneMagasin$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<MagasinDto>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinControllerService.GetOneMagasinPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MagasinDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneMagasin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneMagasin(params: {
    id: number;
  }): Observable<MagasinDto> {

    return this.getOneMagasin$Response(params).pipe(
      map((r: StrictHttpResponse<MagasinDto>) => r.body as MagasinDto)
    );
  }

  /**
   * Path part for operation updateMagasin
   */
  static readonly UpdateMagasinPath = '/api/v2/magasins/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMagasin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMagasin$Response(params: {
    id: number;
    body: MagasinDto
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinControllerService.UpdateMagasinPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMagasin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMagasin(params: {
    id: number;
    body: MagasinDto
  }): Observable<Response> {

    return this.updateMagasin$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

  /**
   * Path part for operation deleteMagasin
   */
  static readonly DeleteMagasinPath = '/api/v2/magasins/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMagasin()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMagasin$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinControllerService.DeleteMagasinPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMagasin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMagasin(params: {
    id: number;
  }): Observable<Response> {

    return this.deleteMagasin$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

}
