import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToasterServices} from "../shared/services/toastr.service";
import {AppStore} from "../store/app.store";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {initialLoginContainerState, LoginContainerState} from "./login-container-state";
import {AuthenticationControllerService} from "../../api/services/authentication-controller.service";
import {AccountControllerService} from "../../api/services/account-controller.service";
import {Users} from "../../api/models/users";
import {FormLogin} from "../../api/models/form-login";
import {LoginResponse} from "../../api/models/login-response";
import {LocalAuthService} from "../shared/local-auth-service";
import {BoutiqueControllerService} from "../../api/services/boutique-controller.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


    public static STATE_KEY = 'loginContainerState';
    state$: LoginContainerState;

    loginForm = new FormGroup({
        username: new FormControl(null, [Validators.required]),
        password: new FormControl(null, [Validators.required]),
        rememberMe: new FormControl(false)
    });


  errorMessage = "Invalid credentials"
  invalidLogin= false
  user: FormLogin = {username:"",password:""}
    role:Array<any>;
    nbreRoles:any ;



  constructor(private route:Router,
              private fb: FormBuilder,
              private appStore$: AppStore,
              private authenticationService: AuthenticationControllerService,
              private localauthService: LocalAuthService,
              private toast:ToasterServices,
              private boutiqueService: BoutiqueControllerService,
              private service: AccountControllerService
              ) {

      this.state$ = appStore$.get(LoginComponent.STATE_KEY, initialLoginContainerState);
      if (this.localauthService.isAuthenticated()) {
          this.route.navigate(['/dashboard']);
      }
  }
              
  ngOnInit() {

      this.loginForm.patchValue(this.state$);
  }

  login(){
      this.loginForm.disable();

      this.state$.isLoading = true;
      this.state$.loadingMessage = 'Connexion, veuillez patienter...';
      this.state$.hasError = false;

    const self = this;

    setTimeout(() => {
    this.authenticationService.login({body:this.loginForm.value}).subscribe(
        (user : LoginResponse) =>{

            self.state$.isLoading = false;
            self.state$.hasError = false;
            this.loginForm.enable();
            this.localauthService.setToken(user.accessToken, self.state$.rememberMe);
            self.appStore$.store('user', user.users);
            this.localauthService.setUserDetails(user.users, self.state$.rememberMe);
            this.localauthService.loadPermission(user.users);
      },
      error =>{
          self.state$.isLoading = false;
          self.state$.hasError = true;
        self.state$.errorMessage = error.status === 0 ? `Impossible de se connecter au serveur` : error.error.message;
          this.loginForm.enable();
      },
        ()=>{
            this.getRoles();
        }
    )
  }, 2000);

  }

    getRoles(){
        const self = this;
        this.service.getMe().subscribe(
            (x:Users) =>{
                this.role = [...x.roles];
                this.nbreRoles = x.roles.length;
            },error=>{

            },
            ()=>{
                this.loadBoutique();
                if (1 ==this.nbreRoles ){
                    // this.route.navigate(['/vente/new'])
                    this.route.navigate(['/dashboard'])
                }
                else if(2 ==this.nbreRoles ){
                    this.route.navigate(['/dashboard'])
                }
            }
        )
    }

    loadBoutique(){
        this.boutiqueService.getAllBoutiques().subscribe(data=>{
            this.localauthService.setStore(data[0])
        })
    }

}
