/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommandeDto } from '../models/commande-dto';
import { FactureDto } from '../models/facture-dto';
import { FactureRequestDto } from '../models/facture-request-dto';
import { OrderModel } from '../models/order-model';

@Injectable({
  providedIn: 'root',
})
export class FactureControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation saveFacture
   */
  static readonly SaveFacturePath = '/api/v2/factures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveFacture()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveFacture$Response(params: {
    body: FactureRequestDto
  }): Observable<StrictHttpResponse<CommandeDto>> {

    const rb = new RequestBuilder(this.rootUrl, FactureControllerService.SaveFacturePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommandeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveFacture$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveFacture(params: {
    body: FactureRequestDto
  }): Observable<CommandeDto> {

    return this.saveFacture$Response(params).pipe(
      map((r: StrictHttpResponse<CommandeDto>) => r.body as CommandeDto)
    );
  }

  /**
   * Path part for operation getAllFactureByCommande
   */
  static readonly GetAllFactureByCommandePath = '/api/v2/factures/commande/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFactureByCommande()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFactureByCommande$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<FactureDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FactureControllerService.GetAllFactureByCommandePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FactureDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllFactureByCommande$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFactureByCommande(params: {
    id: number;
  }): Observable<Array<FactureDto>> {

    return this.getAllFactureByCommande$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FactureDto>>) => r.body as Array<FactureDto>)
    );
  }

  /**
   * Path part for operation printFacture
   */
  static readonly PrintFacturePath = '/api/v2/factures/print/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printFacture()` instead.
   *
   * This method doesn't expect any request body.
   */
  printFacture$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<OrderModel>> {

    const rb = new RequestBuilder(this.rootUrl, FactureControllerService.PrintFacturePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `printFacture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  printFacture(params: {
    id: number;
  }): Observable<OrderModel> {

    return this.printFacture$Response(params).pipe(
      map((r: StrictHttpResponse<OrderModel>) => r.body as OrderModel)
    );
  }

  /**
   * Path part for operation getOneFacture
   */
  static readonly GetOneFacturePath = '/api/v2/factures/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneFacture()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneFacture$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<FactureDto>> {

    const rb = new RequestBuilder(this.rootUrl, FactureControllerService.GetOneFacturePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FactureDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneFacture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneFacture(params: {
    id: number;
  }): Observable<FactureDto> {

    return this.getOneFacture$Response(params).pipe(
      map((r: StrictHttpResponse<FactureDto>) => r.body as FactureDto)
    );
  }

  /**
   * Path part for operation updateFacture
   */
  static readonly UpdateFacturePath = '/api/v2/factures/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFacture()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacture$Response(params: {
    id: number;
    body: FactureDto
  }): Observable<StrictHttpResponse<FactureDto>> {

    const rb = new RequestBuilder(this.rootUrl, FactureControllerService.UpdateFacturePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FactureDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFacture$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacture(params: {
    id: number;
    body: FactureDto
  }): Observable<FactureDto> {

    return this.updateFacture$Response(params).pipe(
      map((r: StrictHttpResponse<FactureDto>) => r.body as FactureDto)
    );
  }

  /**
   * Path part for operation deleteFacture
   */
  static readonly DeleteFacturePath = '/api/v2/factures/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFacture()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacture$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, FactureControllerService.DeleteFacturePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFacture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacture(params: {
    id: number;
  }): Observable<boolean> {

    return this.deleteFacture$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
