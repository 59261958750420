/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommandeDto } from '../models/commande-dto';
import { CommandeRequestDto } from '../models/commande-request-dto';

@Injectable({
  providedIn: 'root',
})
export class CommandeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllCommandes
   */
  static readonly GetAllCommandesPath = '/api/v2/commades';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCommandes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCommandes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommandeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CommandeControllerService.GetAllCommandesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommandeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCommandes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCommandes(params?: {
  }): Observable<Array<CommandeDto>> {

    return this.getAllCommandes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommandeDto>>) => r.body as Array<CommandeDto>)
    );
  }

  /**
   * Path part for operation saveCommande
   */
  static readonly SaveCommandePath = '/api/v2/commades';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCommande()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCommande$Response(params: {
    body: CommandeRequestDto
  }): Observable<StrictHttpResponse<CommandeDto>> {

    const rb = new RequestBuilder(this.rootUrl, CommandeControllerService.SaveCommandePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommandeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCommande$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCommande(params: {
    body: CommandeRequestDto
  }): Observable<CommandeDto> {

    return this.saveCommande$Response(params).pipe(
      map((r: StrictHttpResponse<CommandeDto>) => r.body as CommandeDto)
    );
  }

  /**
   * Path part for operation getOneCommande
   */
  static readonly GetOneCommandePath = '/api/v2/commades/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneCommande()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneCommande$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<CommandeDto>> {

    const rb = new RequestBuilder(this.rootUrl, CommandeControllerService.GetOneCommandePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommandeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneCommande$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneCommande(params: {
    id: number;
  }): Observable<CommandeDto> {

    return this.getOneCommande$Response(params).pipe(
      map((r: StrictHttpResponse<CommandeDto>) => r.body as CommandeDto)
    );
  }

  /**
   * Path part for operation updateCommande
   */
  static readonly UpdateCommandePath = '/api/v2/commades/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCommande()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCommande$Response(params: {
    id: number;
    body: CommandeRequestDto
  }): Observable<StrictHttpResponse<CommandeDto>> {

    const rb = new RequestBuilder(this.rootUrl, CommandeControllerService.UpdateCommandePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommandeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCommande$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCommande(params: {
    id: number;
    body: CommandeRequestDto
  }): Observable<CommandeDto> {

    return this.updateCommande$Response(params).pipe(
      map((r: StrictHttpResponse<CommandeDto>) => r.body as CommandeDto)
    );
  }

  /**
   * Path part for operation deleteCommande
   */
  static readonly DeleteCommandePath = '/api/v2/commades/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCommande()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCommande$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CommandeControllerService.DeleteCommandePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCommande$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCommande(params: {
    id: number;
  }): Observable<boolean> {

    return this.deleteCommande$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
