/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StatCaisseDto } from '../models/stat-caisse-dto';
import { Statistiques } from '../models/statistiques';
import { StockSortieDto } from '../models/stock-sortie-dto';
import { TopArticle } from '../models/top-article';

@Injectable({
  providedIn: 'root',
})
export class StockSortieControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllStockSortieByFacture
   */
  static readonly GetAllStockSortieByFacturePath = '/api/v2/stocksortis';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStockSortieByFacture()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStockSortieByFacture$Response(params?: {
  }): Observable<StrictHttpResponse<Array<StockSortieDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StockSortieControllerService.GetAllStockSortieByFacturePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StockSortieDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllStockSortieByFacture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStockSortieByFacture(params?: {
  }): Observable<Array<StockSortieDto>> {

    return this.getAllStockSortieByFacture$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StockSortieDto>>) => r.body as Array<StockSortieDto>)
    );
  }

  /**
   * Path part for operation getAllStockSortieByArticle
   */
  static readonly GetAllStockSortieByArticlePath = '/api/v2/stocksortis/article/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStockSortieByArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStockSortieByArticle$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<StockSortieDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StockSortieControllerService.GetAllStockSortieByArticlePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StockSortieDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllStockSortieByArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStockSortieByArticle(params: {
    id: number;
  }): Observable<Array<StockSortieDto>> {

    return this.getAllStockSortieByArticle$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StockSortieDto>>) => r.body as Array<StockSortieDto>)
    );
  }

  /**
   * Path part for operation getAllStockSortieByPeriode
   */
  static readonly GetAllStockSortieByPeriodePath = '/api/v2/stocksortis/periode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStockSortieByPeriode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStockSortieByPeriode$Response(params: {
    start: string;
    end: string;
    site: string;
  }): Observable<StrictHttpResponse<Array<StockSortieDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StockSortieControllerService.GetAllStockSortieByPeriodePath, 'post');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('site', params.site, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StockSortieDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllStockSortieByPeriode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStockSortieByPeriode(params: {
    start: string;
    end: string;
    site: string;
  }): Observable<Array<StockSortieDto>> {

    return this.getAllStockSortieByPeriode$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StockSortieDto>>) => r.body as Array<StockSortieDto>)
    );
  }

  /**
   * Path part for operation getStatCaisseByPeriode
   */
  static readonly GetStatCaisseByPeriodePath = '/api/v2/stocksortis/periode/caisse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatCaisseByPeriode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatCaisseByPeriode$Response(params: {
    start: string;
    end: string;
    site: string;
  }): Observable<StrictHttpResponse<StatCaisseDto>> {

    const rb = new RequestBuilder(this.rootUrl, StockSortieControllerService.GetStatCaisseByPeriodePath, 'post');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('site', params.site, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatCaisseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStatCaisseByPeriode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatCaisseByPeriode(params: {
    start: string;
    end: string;
    site: string;
  }): Observable<StatCaisseDto> {

    return this.getStatCaisseByPeriode$Response(params).pipe(
      map((r: StrictHttpResponse<StatCaisseDto>) => r.body as StatCaisseDto)
    );
  }

  /**
   * Path part for operation getStatistiques
   */
  static readonly GetStatistiquesPath = '/api/v2/stocksortis/statistiques';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatistiques()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistiques$Response(params: {
    site: string;
  }): Observable<StrictHttpResponse<Array<Statistiques>>> {

    const rb = new RequestBuilder(this.rootUrl, StockSortieControllerService.GetStatistiquesPath, 'get');
    if (params) {
      rb.query('site', params.site, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Statistiques>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStatistiques$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatistiques(params: {
    site: string;
  }): Observable<Array<Statistiques>> {

    return this.getStatistiques$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Statistiques>>) => r.body as Array<Statistiques>)
    );
  }

  /**
   * Path part for operation gotAllStockSortieTopByNbre
   */
  static readonly GotAllStockSortieTopByNbrePath = '/api/v2/stocksortis/top/article/{nbre}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gotAllStockSortieTopByNbre()` instead.
   *
   * This method doesn't expect any request body.
   */
  gotAllStockSortieTopByNbre$Response(params: {
    nbre: number;
    site: string;
  }): Observable<StrictHttpResponse<Array<TopArticle>>> {

    const rb = new RequestBuilder(this.rootUrl, StockSortieControllerService.GotAllStockSortieTopByNbrePath, 'get');
    if (params) {
      rb.path('nbre', params.nbre, {});
      rb.query('site', params.site, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TopArticle>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gotAllStockSortieTopByNbre$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gotAllStockSortieTopByNbre(params: {
    nbre: number;
    site: string;
  }): Observable<Array<TopArticle>> {

    return this.gotAllStockSortieTopByNbre$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TopArticle>>) => r.body as Array<TopArticle>)
    );
  }

  /**
   * Path part for operation getOneStockSortie
   */
  static readonly GetOneStockSortiePath = '/api/v2/stocksortis/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneStockSortie()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneStockSortie$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<StockSortieDto>> {

    const rb = new RequestBuilder(this.rootUrl, StockSortieControllerService.GetOneStockSortiePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockSortieDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneStockSortie$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneStockSortie(params: {
    id: number;
  }): Observable<StockSortieDto> {

    return this.getOneStockSortie$Response(params).pipe(
      map((r: StrictHttpResponse<StockSortieDto>) => r.body as StockSortieDto)
    );
  }

}
