import {Injectable} from '@angular/core';
import {Subject} from "rxjs/Rx";
import {Compte} from "../../../api/models/compte";
import {SiteDto} from "../../../api/models/site-dto";

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
//communication entre component
  private refresh = new Subject<any>();
  private refreshOp = new Subject<any>();
  private update = new Subject<any>();
  private print = new Subject<any>();

  refreshStream = this.refresh.asObservable();
  refreshOpStream = this.refreshOp.asObservable();
  updateStream = this.update.asObservable();
  printStream = this.print.asObservable();

  constructor() { }

  refrechOperation(data:Compte){
    this.refreshOp.next(data)
  }

  refrechDataSite(site:SiteDto){
    this.refresh.next(site);
  }

  refreshData(){
    this.refresh.next("ok");
  }

  updateData(data){
    this.update.next(data);
  }

  printBill(id){
    this.print.next(id);
  }
}
