import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard', title: 'Tableau de bord', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/manage-user', title: 'Utilisateurs', icon: 'icon-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Enregistrements', icon: 'ft-plus-square', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/create/marque', title: 'Marques Article', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/create/categorie', title: 'Categorie Article', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/create/emplacement', title: 'Emplacement Article', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/create/article', title: 'Article', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Gestion de stock', icon: 'ft-trending-up', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/stock/magasin', title: 'Magasin', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/stock/stock-entrer', title: 'Entrée de stock', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/stock/stock-disponible', title: 'Stock disponible', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/stock/stock-sortir', title: 'Sortie de stock', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]
    }
    ,
    {
        path: '/client', title: 'Clients', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Gestion de compte', icon: 'ft-credit-card', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/manage/compte', title: 'Compte client', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]
    },


    {
        path: '', title: 'Ventes', icon: 'ft-shopping-cart', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/vente/new', title: 'Vendre', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/vente/list', title: 'Mes Ventes', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]
    },
    {
        path: '', title: 'Gestion des charges', icon: 'ft-minus', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/gestion-charge/depense', title: 'Dépenses', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/gestion-charge/charge', title: 'Charges', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }

        ]
    },
    {
        path: '/statistiques-caisse', title: 'Caisse', icon: 'icon-wallet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/statistiques', title: 'Statistiques', icon: 'ft-pie-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/configuration', title: 'Configuration', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    
];
