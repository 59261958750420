import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {LocalAuthService} from "../shared/local-auth-service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private route:Router,private localAuthService: LocalAuthService,) { }

  ngOnInit() {

      this.localAuthService.logout();
      this.route.navigate(['login']);
  }

}
