/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StockentreDto } from '../models/stockentre-dto';
import { StockentreRequestDto } from '../models/stockentre-request-dto';

@Injectable({
  providedIn: 'root',
})
export class StockentreControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllStockentres
   */
  static readonly GetAllStockentresPath = '/api/v2/stockentres';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStockentres()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStockentres$Response(params?: {
  }): Observable<StrictHttpResponse<Array<StockentreDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StockentreControllerService.GetAllStockentresPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StockentreDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllStockentres$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStockentres(params?: {
  }): Observable<Array<StockentreDto>> {

    return this.getAllStockentres$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StockentreDto>>) => r.body as Array<StockentreDto>)
    );
  }

  /**
   * Path part for operation saveStockentre
   */
  static readonly SaveStockentrePath = '/api/v2/stockentres';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveStockentre()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveStockentre$Response(params: {
    body: StockentreRequestDto
  }): Observable<StrictHttpResponse<StockentreDto>> {

    const rb = new RequestBuilder(this.rootUrl, StockentreControllerService.SaveStockentrePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockentreDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveStockentre$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveStockentre(params: {
    body: StockentreRequestDto
  }): Observable<StockentreDto> {

    return this.saveStockentre$Response(params).pipe(
      map((r: StrictHttpResponse<StockentreDto>) => r.body as StockentreDto)
    );
  }

  /**
   * Path part for operation getOneStockentre
   */
  static readonly GetOneStockentrePath = '/api/v2/stockentres/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneStockentre()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneStockentre$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<StockentreDto>> {

    const rb = new RequestBuilder(this.rootUrl, StockentreControllerService.GetOneStockentrePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockentreDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneStockentre$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneStockentre(params: {
    id: number;
  }): Observable<StockentreDto> {

    return this.getOneStockentre$Response(params).pipe(
      map((r: StrictHttpResponse<StockentreDto>) => r.body as StockentreDto)
    );
  }

  /**
   * Path part for operation updateStockentre
   */
  static readonly UpdateStockentrePath = '/api/v2/stockentres/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStockentre()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateStockentre$Response(params: {
    id: number;
    body: StockentreRequestDto
  }): Observable<StrictHttpResponse<StockentreDto>> {

    const rb = new RequestBuilder(this.rootUrl, StockentreControllerService.UpdateStockentrePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StockentreDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateStockentre$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateStockentre(params: {
    id: number;
    body: StockentreRequestDto
  }): Observable<StockentreDto> {

    return this.updateStockentre$Response(params).pipe(
      map((r: StrictHttpResponse<StockentreDto>) => r.body as StockentreDto)
    );
  }

}
