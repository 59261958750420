/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MagasinArticlesDto } from '../models/magasin-articles-dto';
import { Response } from '../models/response';

@Injectable({
  providedIn: 'root',
})
export class MagasinArticlesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllMagasinArticles
   */
  static readonly GetAllMagasinArticlesPath = '/api/v2/magasin-articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMagasinArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMagasinArticles$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MagasinArticlesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinArticlesControllerService.GetAllMagasinArticlesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MagasinArticlesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllMagasinArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMagasinArticles(params?: {
  }): Observable<Array<MagasinArticlesDto>> {

    return this.getAllMagasinArticles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MagasinArticlesDto>>) => r.body as Array<MagasinArticlesDto>)
    );
  }

  /**
   * Path part for operation addMagasinArticle
   */
  static readonly AddMagasinArticlePath = '/api/v2/magasin-articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMagasinArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMagasinArticle$Response(params: {
    body: MagasinArticlesDto
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinArticlesControllerService.AddMagasinArticlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addMagasinArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMagasinArticle(params: {
    body: MagasinArticlesDto
  }): Observable<Response> {

    return this.addMagasinArticle$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

  /**
   * Path part for operation getOneMagasinArticle
   */
  static readonly GetOneMagasinArticlePath = '/api/v2/magasin-articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneMagasinArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneMagasinArticle$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinArticlesControllerService.GetOneMagasinArticlePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneMagasinArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneMagasinArticle(params: {
    id: number;
  }): Observable<Response> {

    return this.getOneMagasinArticle$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

  /**
   * Path part for operation updateMagasinArticle
   */
  static readonly UpdateMagasinArticlePath = '/api/v2/magasin-articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMagasinArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMagasinArticle$Response(params: {
    id: number;
    body: MagasinArticlesDto
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinArticlesControllerService.UpdateMagasinArticlePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMagasinArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMagasinArticle(params: {
    id: number;
    body: MagasinArticlesDto
  }): Observable<Response> {

    return this.updateMagasinArticle$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

  /**
   * Path part for operation deleteMagasinArticle
   */
  static readonly DeleteMagasinArticlePath = '/api/v2/magasin-articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMagasinArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMagasinArticle$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinArticlesControllerService.DeleteMagasinArticlePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMagasinArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMagasinArticle(params: {
    id: number;
  }): Observable<Response> {

    return this.deleteMagasinArticle$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

  /**
   * Path part for operation getAllMagasinArticleByStore
   */
  static readonly GetAllMagasinArticleByStorePath = '/api/v2/magasin-articles/{id}/store';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMagasinArticleByStore()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMagasinArticleByStore$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<MagasinArticlesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MagasinArticlesControllerService.GetAllMagasinArticleByStorePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MagasinArticlesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllMagasinArticleByStore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMagasinArticleByStore(params: {
    id: number;
  }): Observable<Array<MagasinArticlesDto>> {

    return this.getAllMagasinArticleByStore$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MagasinArticlesDto>>) => r.body as Array<MagasinArticlesDto>)
    );
  }

}
