/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RolesDto } from '../models/roles-dto';
import { Users } from '../models/users';
import { UsersDto } from '../models/users-dto';
import { UsersRequestDto } from '../models/users-request-dto';
import { UsersResponseDto } from '../models/users-response-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAll
   */
  static readonly GetAllPath = '/api/v2/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UsersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UsersDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params?: {
  }): Observable<Array<UsersDto>> {

    return this.getAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsersDto>>) => r.body as Array<UsersDto>)
    );
  }

  /**
   * Path part for operation addUser
   */
  static readonly AddUserPath = '/api/v2/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUser$Response(params: {
    body: UsersRequestDto
  }): Observable<StrictHttpResponse<UsersDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.AddUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsersDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUser(params: {
    body: UsersRequestDto
  }): Observable<UsersDto> {

    return this.addUser$Response(params).pipe(
      map((r: StrictHttpResponse<UsersDto>) => r.body as UsersDto)
    );
  }

  /**
   * Path part for operation getLogedUser
   */
  static readonly GetLogedUserPath = '/api/v2/users/getLogedUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogedUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetLogedUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogedUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser(params?: {
  }): Observable<{
[key: string]: {
};
}> {

    return this.getLogedUser$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>) => r.body as {
[key: string]: {
};
})
    );
  }

  /**
   * Path part for operation getLogedUser3
   */
  static readonly GetLogedUser3Path = '/api/v2/users/getLogedUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogedUser3()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser3$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetLogedUser3Path, 'put');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogedUser3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser3(params?: {
  }): Observable<{
[key: string]: {
};
}> {

    return this.getLogedUser3$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>) => r.body as {
[key: string]: {
};
})
    );
  }

  /**
   * Path part for operation getLogedUser2
   */
  static readonly GetLogedUser2Path = '/api/v2/users/getLogedUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogedUser2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser2$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetLogedUser2Path, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogedUser2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser2(params?: {
  }): Observable<{
[key: string]: {
};
}> {

    return this.getLogedUser2$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>) => r.body as {
[key: string]: {
};
})
    );
  }

  /**
   * Path part for operation getLogedUser5
   */
  static readonly GetLogedUser5Path = '/api/v2/users/getLogedUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogedUser5()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser5$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetLogedUser5Path, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogedUser5$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser5(params?: {
  }): Observable<{
[key: string]: {
};
}> {

    return this.getLogedUser5$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>) => r.body as {
[key: string]: {
};
})
    );
  }

  /**
   * Path part for operation getLogedUser6
   */
  static readonly GetLogedUser6Path = '/api/v2/users/getLogedUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogedUser6()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser6$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetLogedUser6Path, 'options');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogedUser6$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser6(params?: {
  }): Observable<{
[key: string]: {
};
}> {

    return this.getLogedUser6$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>) => r.body as {
[key: string]: {
};
})
    );
  }

  /**
   * Path part for operation getLogedUser1
   */
  static readonly GetLogedUser1Path = '/api/v2/users/getLogedUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogedUser1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser1$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetLogedUser1Path, 'head');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogedUser1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser1(params?: {
  }): Observable<{
[key: string]: {
};
}> {

    return this.getLogedUser1$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>) => r.body as {
[key: string]: {
};
})
    );
  }

  /**
   * Path part for operation getLogedUser4
   */
  static readonly GetLogedUser4Path = '/api/v2/users/getLogedUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogedUser4()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser4$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetLogedUser4Path, 'patch');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogedUser4$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogedUser4(params?: {
  }): Observable<{
[key: string]: {
};
}> {

    return this.getLogedUser4$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>) => r.body as {
[key: string]: {
};
})
    );
  }

  /**
   * Path part for operation getMe
   */
  static readonly GetMePath = '/api/v2/users/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe$Response(params?: {
  }): Observable<StrictHttpResponse<UsersResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetMePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsersResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe(params?: {
  }): Observable<UsersResponseDto> {

    return this.getMe$Response(params).pipe(
      map((r: StrictHttpResponse<UsersResponseDto>) => r.body as UsersResponseDto)
    );
  }

  /**
   * Path part for operation getRoles
   */
  static readonly GetRolesPath = '/api/v2/users/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RolesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.GetRolesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RolesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoles(params?: {
  }): Observable<Array<RolesDto>> {

    return this.getRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RolesDto>>) => r.body as Array<RolesDto>)
    );
  }

  /**
   * Path part for operation deleteUsersBatch
   */
  static readonly DeleteUsersBatchPath = '/api/v2/users/{ids}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUsersBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUsersBatch$Response(params: {
    ids: Array<number>;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.DeleteUsersBatchPath, 'delete');
    if (params) {
      rb.path('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUsersBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUsersBatch(params: {
    ids: Array<number>;
  }): Observable<void> {

    return this.deleteUsersBatch$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation update
   */
  static readonly UpdatePath = '/api/v2/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: {
    id: number;
    body: Users
  }): Observable<StrictHttpResponse<Users>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.UpdatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Users>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: {
    id: number;
    body: Users
  }): Observable<Users> {

    return this.update$Response(params).pipe(
      map((r: StrictHttpResponse<Users>) => r.body as Users)
    );
  }

  /**
   * Path part for operation delete
   */
  static readonly DeletePath = '/api/v2/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.DeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: {
    id: number;
  }): Observable<{
}> {

    return this.delete$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation setActive
   */
  static readonly SetActivePath = '/api/v2/users/{id}/active/{active}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setActive()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActive$Response(params: {
    id: number;
    active: boolean;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.SetActivePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setActive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActive(params: {
    id: number;
    active: boolean;
  }): Observable<void> {

    return this.setActive$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setRoles
   */
  static readonly SetRolesPath = '/api/v2/users/{userId}/roles/roleIds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  setRoles$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.SetRolesPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setRoles(params: {
    userId: number;
  }): Observable<void> {

    return this.setRoles$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addRolesToUser
   */
  static readonly AddRolesToUserPath = '/api/v2/users/{userId}/roles/{roleIds}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRolesToUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  addRolesToUser$Response(params: {
    userId: number;
    roleIds: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.AddRolesToUserPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('roleIds', params.roleIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRolesToUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addRolesToUser(params: {
    userId: number;
    roleIds: number;
  }): Observable<void> {

    return this.addRolesToUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeRolesFromUser
   */
  static readonly RemoveRolesFromUserPath = '/api/v2/users/{userId}/roles/{roleIds}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRolesFromUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRolesFromUser$Response(params: {
    userId: number;
    roleIds: Array<number>;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.RemoveRolesFromUserPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('roleIds', params.roleIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeRolesFromUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRolesFromUser(params: {
    userId: number;
    roleIds: Array<number>;
  }): Observable<void> {

    return this.removeRolesFromUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
