/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChargeDto } from '../models/charge-dto';

@Injectable({
  providedIn: 'root',
})
export class ChargeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllCharges
   */
  static readonly GetAllChargesPath = '/api/v2/charges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCharges()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCharges$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ChargeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ChargeControllerService.GetAllChargesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChargeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCharges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCharges(params?: {
  }): Observable<Array<ChargeDto>> {

    return this.getAllCharges$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChargeDto>>) => r.body as Array<ChargeDto>)
    );
  }

  /**
   * Path part for operation addCharge
   */
  static readonly AddChargePath = '/api/v2/charges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCharge()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCharge$Response(params: {
    body: ChargeDto
  }): Observable<StrictHttpResponse<ChargeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChargeControllerService.AddChargePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChargeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCharge$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCharge(params: {
    body: ChargeDto
  }): Observable<ChargeDto> {

    return this.addCharge$Response(params).pipe(
      map((r: StrictHttpResponse<ChargeDto>) => r.body as ChargeDto)
    );
  }

  /**
   * Path part for operation getCharge
   */
  static readonly GetChargePath = '/api/v2/charges/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCharge()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCharge$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<ChargeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChargeControllerService.GetChargePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChargeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCharge$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCharge(params: {
    id: number;
  }): Observable<ChargeDto> {

    return this.getCharge$Response(params).pipe(
      map((r: StrictHttpResponse<ChargeDto>) => r.body as ChargeDto)
    );
  }

  /**
   * Path part for operation updateCharge
   */
  static readonly UpdateChargePath = '/api/v2/charges/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCharge()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCharge$Response(params: {
    id: number;
    body: ChargeDto
  }): Observable<StrictHttpResponse<ChargeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ChargeControllerService.UpdateChargePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChargeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCharge$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCharge(params: {
    id: number;
    body: ChargeDto
  }): Observable<ChargeDto> {

    return this.updateCharge$Response(params).pipe(
      map((r: StrictHttpResponse<ChargeDto>) => r.body as ChargeDto)
    );
  }

  /**
   * Path part for operation deleteCharge
   */
  static readonly DeleteChargePath = '/api/v2/charges/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCharge()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCharge$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ChargeControllerService.DeleteChargePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCharge$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCharge(params: {
    id: number;
  }): Observable<boolean> {

    return this.deleteCharge$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
