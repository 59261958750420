import {Routes} from '@angular/router';
import {NgxPermissionsGuard} from "ngx-permissions";

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
    {
        path: '',
        loadChildren: './pages/content-pages/content-pages.module#ContentPagesModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }
    },
    {
        path: '',
        loadChildren: './pages/users/users.module#UsersModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }
    },
    {
        path: 'create',
        loadChildren: './pages/marque/marque.module#MarqueModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }
    },
    {
        path: 'create',
        loadChildren: './pages/categorie/categorie.module#CategorieModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }
    },
    {
        path: 'create',
        loadChildren: './pages/emplacement/emplacement.module#EmplacementModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }

    },
    {
        path: 'create',
        loadChildren: './pages/article/article.module#ArticleModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }
    },
    {
        path: 'stock',
        loadChildren: './pages/stock/stock.module#StockModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }
    }
    ,
    {
        path: '',
        loadChildren: './pages/client/client.module#ClientModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }
    },
    {
        path: 'vente',
        loadChildren: './pages/ventes/ventes.module#VentesModule',

    },
    {
        path: '',
        loadChildren: './pages/caisse/caisse.module#CaisseModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }
    },
    {
        path: '',
        loadChildren: './pages/statistique/statistique.module#StatistiqueModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }
    },
    {
        path: 'manage',
        loadChildren: './pages/gestion-compte/gestion-compte.module#GestionCompteModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }

    }
    ,
    {
        path: 'gestion-charge',
        loadChildren: './pages/gestion-charge/gestion-charge.module#GestionChargeModule',
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions:{
                only:'ADMIN',
                redirectTo:'/vente/new'
            }
        }

    }
];
