/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DepenseDto } from '../models/depense-dto';

@Injectable({
  providedIn: 'root',
})
export class DepenseControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllDepenses
   */
  static readonly GetAllDepensesPath = '/api/v2/depenses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDepenses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDepenses$Response(params?: {
  }): Observable<StrictHttpResponse<Array<DepenseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DepenseControllerService.GetAllDepensesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DepenseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllDepenses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDepenses(params?: {
  }): Observable<Array<DepenseDto>> {

    return this.getAllDepenses$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DepenseDto>>) => r.body as Array<DepenseDto>)
    );
  }

  /**
   * Path part for operation addDepense
   */
  static readonly AddDepensePath = '/api/v2/depenses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDepense()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addDepense$Response(params: {
    body: DepenseDto
  }): Observable<StrictHttpResponse<DepenseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DepenseControllerService.AddDepensePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepenseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addDepense$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addDepense(params: {
    body: DepenseDto
  }): Observable<DepenseDto> {

    return this.addDepense$Response(params).pipe(
      map((r: StrictHttpResponse<DepenseDto>) => r.body as DepenseDto)
    );
  }

  /**
   * Path part for operation getDepense
   */
  static readonly GetDepensePath = '/api/v2/depenses/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDepense()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepense$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<DepenseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DepenseControllerService.GetDepensePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepenseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDepense$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepense(params: {
    id: number;
  }): Observable<DepenseDto> {

    return this.getDepense$Response(params).pipe(
      map((r: StrictHttpResponse<DepenseDto>) => r.body as DepenseDto)
    );
  }

  /**
   * Path part for operation updateDepense
   */
  static readonly UpdateDepensePath = '/api/v2/depenses/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDepense()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDepense$Response(params: {
    id: number;
    body: DepenseDto
  }): Observable<StrictHttpResponse<DepenseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DepenseControllerService.UpdateDepensePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepenseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDepense$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDepense(params: {
    id: number;
    body: DepenseDto
  }): Observable<DepenseDto> {

    return this.updateDepense$Response(params).pipe(
      map((r: StrictHttpResponse<DepenseDto>) => r.body as DepenseDto)
    );
  }

  /**
   * Path part for operation deleteDepense
   */
  static readonly DeleteDepensePath = '/api/v2/depenses/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDepense()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDepense$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, DepenseControllerService.DeleteDepensePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDepense$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDepense(params: {
    id: number;
  }): Observable<boolean> {

    return this.deleteDepense$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
