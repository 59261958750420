/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BoutiqueDto } from '../models/boutique-dto';
import { Response } from '../models/response';

@Injectable({
  providedIn: 'root',
})
export class BoutiqueControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllBoutiques
   */
  static readonly GetAllBoutiquesPath = '/api/v2/boutiques';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBoutiques()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBoutiques$Response(params?: {
  }): Observable<StrictHttpResponse<Array<BoutiqueDto>>> {

    const rb = new RequestBuilder(this.rootUrl, BoutiqueControllerService.GetAllBoutiquesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BoutiqueDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllBoutiques$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBoutiques(params?: {
  }): Observable<Array<BoutiqueDto>> {

    return this.getAllBoutiques$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BoutiqueDto>>) => r.body as Array<BoutiqueDto>)
    );
  }

  /**
   * Path part for operation add
   */
  static readonly AddPath = '/api/v2/boutiques';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `add()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  add$Response(params: {
    body: BoutiqueDto
  }): Observable<StrictHttpResponse<BoutiqueDto>> {

    const rb = new RequestBuilder(this.rootUrl, BoutiqueControllerService.AddPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BoutiqueDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `add$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  add(params: {
    body: BoutiqueDto
  }): Observable<BoutiqueDto> {

    return this.add$Response(params).pipe(
      map((r: StrictHttpResponse<BoutiqueDto>) => r.body as BoutiqueDto)
    );
  }

  /**
   * Path part for operation getOneBoutique
   */
  static readonly GetOneBoutiquePath = '/api/v2/boutiques/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneBoutique()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneBoutique$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Response>> {

    const rb = new RequestBuilder(this.rootUrl, BoutiqueControllerService.GetOneBoutiquePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Response>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneBoutique$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneBoutique(params: {
    id: number;
  }): Observable<Response> {

    return this.getOneBoutique$Response(params).pipe(
      map((r: StrictHttpResponse<Response>) => r.body as Response)
    );
  }

}
