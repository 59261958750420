/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Fournisseur } from '../models/fournisseur';
import { FournisseurDto } from '../models/fournisseur-dto';

@Injectable({
  providedIn: 'root',
})
export class FournisseurControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllFournisseurs
   */
  static readonly GetAllFournisseursPath = '/api/v2/fournisseur';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFournisseurs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFournisseurs$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Fournisseur>>> {

    const rb = new RequestBuilder(this.rootUrl, FournisseurControllerService.GetAllFournisseursPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Fournisseur>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllFournisseurs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFournisseurs(params?: {
  }): Observable<Array<Fournisseur>> {

    return this.getAllFournisseurs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Fournisseur>>) => r.body as Array<Fournisseur>)
    );
  }

  /**
   * Path part for operation addFournisseur
   */
  static readonly AddFournisseurPath = '/api/v2/fournisseur';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFournisseur()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFournisseur$Response(params: {
    body: FournisseurDto
  }): Observable<StrictHttpResponse<Fournisseur>> {

    const rb = new RequestBuilder(this.rootUrl, FournisseurControllerService.AddFournisseurPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Fournisseur>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addFournisseur$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFournisseur(params: {
    body: FournisseurDto
  }): Observable<Fournisseur> {

    return this.addFournisseur$Response(params).pipe(
      map((r: StrictHttpResponse<Fournisseur>) => r.body as Fournisseur)
    );
  }

  /**
   * Path part for operation getOneFournisseur
   */
  static readonly GetOneFournisseurPath = '/api/v2/fournisseur/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneFournisseur()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneFournisseur$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Fournisseur>> {

    const rb = new RequestBuilder(this.rootUrl, FournisseurControllerService.GetOneFournisseurPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Fournisseur>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneFournisseur$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneFournisseur(params: {
    id: number;
  }): Observable<Fournisseur> {

    return this.getOneFournisseur$Response(params).pipe(
      map((r: StrictHttpResponse<Fournisseur>) => r.body as Fournisseur)
    );
  }

  /**
   * Path part for operation updateFournisseur
   */
  static readonly UpdateFournisseurPath = '/api/v2/fournisseur/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFournisseur()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFournisseur$Response(params: {
    id: number;
    body: FournisseurDto
  }): Observable<StrictHttpResponse<Fournisseur>> {

    const rb = new RequestBuilder(this.rootUrl, FournisseurControllerService.UpdateFournisseurPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Fournisseur>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFournisseur$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFournisseur(params: {
    id: number;
    body: FournisseurDto
  }): Observable<Fournisseur> {

    return this.updateFournisseur$Response(params).pipe(
      map((r: StrictHttpResponse<Fournisseur>) => r.body as Fournisseur)
    );
  }

  /**
   * Path part for operation deleteFournisseur
   */
  static readonly DeleteFournisseurPath = '/api/v2/fournisseur/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFournisseur()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFournisseur$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FournisseurControllerService.DeleteFournisseurPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFournisseur$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFournisseur(params: {
    id: number;
  }): Observable<void> {

    return this.deleteFournisseur$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
