/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ArticleDto } from '../models/article-dto';
import { ArticleRequestDto } from '../models/article-request-dto';

@Injectable({
  providedIn: 'root',
})
export class ArticleControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllArticles
   */
  static readonly GetAllArticlesPath = '/api/v2/articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllArticles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArticles$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ArticleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ArticleControllerService.GetAllArticlesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ArticleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllArticles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllArticles(params?: {
  }): Observable<Array<ArticleDto>> {

    return this.getAllArticles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ArticleDto>>) => r.body as Array<ArticleDto>)
    );
  }

  /**
   * Path part for operation saveArticle
   */
  static readonly SaveArticlePath = '/api/v2/articles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveArticle$Response(params: {
    body: ArticleRequestDto
  }): Observable<StrictHttpResponse<ArticleDto>> {

    const rb = new RequestBuilder(this.rootUrl, ArticleControllerService.SaveArticlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArticleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveArticle(params: {
    body: ArticleRequestDto
  }): Observable<ArticleDto> {

    return this.saveArticle$Response(params).pipe(
      map((r: StrictHttpResponse<ArticleDto>) => r.body as ArticleDto)
    );
  }

  /**
   * Path part for operation getOneArticle
   */
  static readonly GetOneArticlePath = '/api/v2/articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOneArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneArticle$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<ArticleDto>> {

    const rb = new RequestBuilder(this.rootUrl, ArticleControllerService.GetOneArticlePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArticleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOneArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOneArticle(params: {
    id: number;
  }): Observable<ArticleDto> {

    return this.getOneArticle$Response(params).pipe(
      map((r: StrictHttpResponse<ArticleDto>) => r.body as ArticleDto)
    );
  }

  /**
   * Path part for operation updateArticle
   */
  static readonly UpdateArticlePath = '/api/v2/articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArticle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArticle$Response(params: {
    id: number;
    body: ArticleRequestDto
  }): Observable<StrictHttpResponse<ArticleDto>> {

    const rb = new RequestBuilder(this.rootUrl, ArticleControllerService.UpdateArticlePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ArticleDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateArticle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArticle(params: {
    id: number;
    body: ArticleRequestDto
  }): Observable<ArticleDto> {

    return this.updateArticle$Response(params).pipe(
      map((r: StrictHttpResponse<ArticleDto>) => r.body as ArticleDto)
    );
  }

  /**
   * Path part for operation deleteArticle
   */
  static readonly DeleteArticlePath = '/api/v2/articles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArticle$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ArticleControllerService.DeleteArticlePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArticle(params: {
    id: number;
  }): Observable<boolean> {

    return this.deleteArticle$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation setPicture
   */
  static readonly SetPicturePath = '/api/v2/articles/{id}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPicture$Any()` instead.
   *
   * This method sends `application/octet-stream` and handles request body of type `application/octet-stream`.
   */
  setPicture$Any$Response(params: {
    id: number;
    body?: {
'picture': Blob;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ArticleControllerService.SetPicturePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/octet-stream');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setPicture$Any$Response()` instead.
   *
   * This method sends `application/octet-stream` and handles request body of type `application/octet-stream`.
   */
  setPicture$Any(params: {
    id: number;
    body?: {
'picture': Blob;
}
  }): Observable<void> {

    return this.setPicture$Any$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPicture$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  setPicture$FormData$Response(params: {
    id: number;
    body?: {
'picture': Blob;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ArticleControllerService.SetPicturePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setPicture$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  setPicture$FormData(params: {
    id: number;
    body?: {
'picture': Blob;
}
  }): Observable<void> {

    return this.setPicture$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
