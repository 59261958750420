/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class MediaControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDocumentByPath
   */
  static readonly GetDocumentByPathPath = '/media/document';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentByPath()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByPath$Response(params: {
    path: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaControllerService.GetDocumentByPathPath, 'get');
    if (params) {
      rb.query('path', params.path, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentByPath$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentByPath(params: {
    path: string;
  }): Observable<string> {

    return this.getDocumentByPath$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getStatCaissePdf
   */
  static readonly GetStatCaissePdfPath = '/media/etat/caisse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatCaissePdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatCaissePdf$Response(params: {
    start: string;
    end: string;
    site: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaControllerService.GetStatCaissePdfPath, 'get');
    if (params) {
      rb.query('start', params.start, {});
      rb.query('end', params.end, {});
      rb.query('site', params.site, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStatCaissePdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatCaissePdf(params: {
    start: string;
    end: string;
    site: string;
  }): Observable<string> {

    return this.getStatCaissePdf$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getImageByPath
   */
  static readonly GetImageByPathPath = '/media/picture';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImageByPath()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageByPath$Response(params: {
    path: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MediaControllerService.GetImageByPathPath, 'get');
    if (params) {
      rb.query('path', params.path, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImageByPath$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageByPath(params: {
    path: string;
  }): Observable<string> {

    return this.getImageByPath$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
